import React from "react";
import { useTheme } from "@mui/material";

export function SectionLineIcon(): React.ReactElement {
  const theme = useTheme();

  return (
    <svg
      height="2"
      viewBox="0 0 35.215 2"
      width="35.215"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1h35.215"
        data-name="Path 6235"
        fill={theme.palette.primary.light}
        stroke={theme.palette.primary.light}
        strokeWidth="2"
      />
    </svg>
  );
}
